import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { tabletUp, desktopUp, mobileOnly, truncateTextCss } from 'styles/utils';
import { typography } from 'styles/theme';

import TextWithoutMui from './TextWithoutMui';

function fontSizeByMediaQuery(fontSize, mediaQuery) {
  return css`
    ${mediaQuery} {
      font-size: ${fontSize}rem;
    }
  `;
}

// Map variant props to an HTML element
const variantMapping = {
  body1: 'div',
  body2: 'p',
  caption: 'div',
};

const body1Css = css`
  &.MuiTypography-body1 {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.5;
  }
`;

const body2Css = css`
  &.MuiTypography-body2 {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.83;
  }
`;

const captionCss = css`
  &.MuiTypography-caption {
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1.64;
  }
`;

const variantCss = {
  body1: body1Css,
  body2: body2Css,
  caption: captionCss,
};

const muiTypographyCss = ({
  color,
  serifFont,
  variant,
  uppercase,
  fontWeight,
  fontSize,
  lineHeight,
  textAlign,
  mobileFontSize,
  desktopFontSize,
  tabletFontSize,
  truncate,
  wordBreak,
}) => css`
  /* Variant styling */
  ${variantCss[variant]}

  /* Shared */
  &.MuiTypography-root {
    font-family: ${serifFont && typography.fontSerif};
    font-weight: ${fontWeight};
    font-size: ${fontSize && `${fontSize}rem`};
    text-transform: ${uppercase && 'uppercase'};
    text-align: ${textAlign};
    line-height: ${lineHeight};
    color: ${color};
    word-break: ${wordBreak};

    ${mobileFontSize && fontSizeByMediaQuery(mobileFontSize, mobileOnly)}
    ${tabletFontSize && fontSizeByMediaQuery(tabletFontSize, tabletUp)}
    ${desktopFontSize && fontSizeByMediaQuery(desktopFontSize, desktopUp)}

    ${truncate && truncateTextCss(truncate)};
  }

  &.MuiTypography-gutterBottom {
    margin-bottom: 0.5em;
  }
`;

/**
 * A React component for displaying text.
 * It wraps Material UI's `Typography` component.
 *
 * @see
 * [Typography API](https://material-ui.com/api/typography/)
 *
 * @param {object} props
 */
function Text({
  children,
  isMui = true,
  color = 'inherit',
  serifFont = false,
  variant = 'body1',
  uppercase = false,
  fontWeight,
  fontSize,
  fontSizes,
  lineHeight,
  textAlign,
  mobileOnly,
  tabletUp,
  desktopUp,
  truncate,
  wordBreak,
  ...restProps
}) {
  const mobileFontSize = mobileOnly;
  const tabletFontSize = tabletUp;
  const desktopFontSize = desktopUp;

  return !isMui ? (
    <TextWithoutMui
      color={color}
      fontSize={fontSize}
      fontSizes={fontSizes}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      serifFont={serifFont}
      textAlign={textAlign}
      truncate={truncate}
      wordBreak={wordBreak}
      uppercase={uppercase}
      {...restProps}
    >
      {children}
    </TextWithoutMui>
  ) : (
    <Typography
      variantMapping={variantMapping}
      variant={variant}
      css={muiTypographyCss({
        color,
        serifFont,
        variant,
        uppercase,
        fontWeight,
        fontSize,
        lineHeight,
        textAlign,
        mobileFontSize,
        desktopFontSize,
        tabletFontSize,
        truncate,
        wordBreak,
      })}
      {...restProps}
    >
      {children}
    </Typography>
  );
}

Text.propTypes = {
  /** Render the TextWithoutMui component */
  isMui: PropTypes.bool,
  children: PropTypes.node,
  /** Color of the text. Can be any valid CSS color */
  color: PropTypes.string,
  /** Font-weight of the text. Can be any valid CSS font-weight */
  fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Sets a serif font-family */
  serifFont: PropTypes.bool,
  /** Variant of the text. Mostly Material UI's variants */
  variant: PropTypes.string,
  /** Font size of the text, either in rem units (number) or a string */
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Related to the TextWithoutMui component */
  fontSizes: PropTypes.object,
  /** Line height of the text */
  lineHeight: PropTypes.number,
  /** Font size of text on mobile */
  mobileOnly: PropTypes.number,
  /** Font size of text on tablet screens and above */
  tabletUp: PropTypes.number,
  /** Font size of text on desktop screen and above */
  desktopUp: PropTypes.number,
  /** Set the text-align on the component */
  textAlign: PropTypes.string,
  /** Sets text as uppercase */
  uppercase: PropTypes.bool,
  /** Truncate text. Represents the number of lines to clamp */
  truncate: PropTypes.number,
  /** How words should break when reaching the end of a line */
  wordBreak: PropTypes.string,
};

export default Text;
