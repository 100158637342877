import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { fontSize, typography } from 'styles/theme';
import {
  desktopUp,
  mobileOnly,
  tabletOnly,
  tabletUp,
  truncateTextCss,
} from 'styles/utils';

const mediaQuery = {
  mobileOnly,
  tabletOnly,
  tabletUp,
  desktopUp,
};

const fontSizesCss = ({ fontSizes }) =>
  fontSizes &&
  Object.entries(fontSizes)
    .filter(([query]) => mediaQuery[query])
    .map(
      ([query, fontSize]) =>
        css`
          ${mediaQuery[query]} {
            font-size: ${fontSize};
          }
        `
    );

const textCss = props => css`
  ${props.color &&
  css`
    color: ${props.color};
  `}

  ${props.fontSize &&
  css`
    font-size: ${props.fontSize};
  `}

  ${props.fontWeight &&
  css`
    font-weight: ${props.fontWeight};
  `}

  ${props.lineHeight &&
  css`
    line-height: ${props.lineHeight};
  `}

  ${props.marginBottom &&
  css`
    margin-bottom: 0.6em;
  `}

  ${props.paragraph &&
  css`
    line-height: 1.83;
    margin-bottom: 1em;
  `}

  ${props.serifFont &&
  css`
    font-family: ${typography.fontSerif};
  `}

  ${props.textAlign &&
  css`
    text-align: ${props.textAlign};
  `};

  ${props.truncate && truncateTextCss(props.truncate)};

  ${props.uppercase &&
  css`
    text-transform: uppercase;
  `}

  ${props.wordBreak &&
  css`
    word-break: ${props.wordBreak};
  `}
`;

function TextWithoutMui({
  as: Component = 'p',
  children,
  color,
  fontSize,
  fontSizes,
  fontWeight,
  lineHeight = 1.5,
  marginBottom,
  paragraph,
  serifFont,
  textAlign,
  truncate,
  uppercase,
  wordBreak,
  ...restProps
}) {
  return (
    <Component
      css={[
        textCss({
          color,
          fontSize,
          fontWeight,
          lineHeight,
          marginBottom,
          paragraph,
          serifFont,
          textAlign,
          truncate,
          uppercase,
          wordBreak,
        }),
        fontSizesCss({ fontSizes }),
      ]}
      {...restProps}
    >
      {children}
    </Component>
  );
}

TextWithoutMui.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  color: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  serifFont: PropTypes.bool,
  fontSize: PropTypes.oneOf(Object.values(fontSize)),
  fontSizes: PropTypes.shape({
    desktopUp: PropTypes.oneOf(Object.values(fontSize)),
    mobileOnly: PropTypes.oneOf(Object.values(fontSize)),
    tabletOnly: PropTypes.oneOf(Object.values(fontSize)),
    tabletUp: PropTypes.oneOf(Object.values(fontSize)),
  }),
  marginBottom: PropTypes.bool,
  paragraph: PropTypes.bool,
  lineHeight: PropTypes.number,
  textAlign: PropTypes.string,
  uppercase: PropTypes.bool,
  /** Truncate text. Represents the number of lines to clamp */
  truncate: PropTypes.number,
  wordBreak: PropTypes.string,
};

export default TextWithoutMui;
